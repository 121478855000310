// 订单确认
<template>
  <div>
    <el-card>
      <div class="additem-title f14">
        <span>确认订单</span>
      </div>
      <div class="flex order-list">
        <div class="t-c">
          <div>订单类型</div>
          <div
            class="mt-20"
          >{{type==1? '年费': type==2?'转卖':type==3? '余额充值':type==4? '监控购买':type==5?'合同缴费':''}}</div>
        </div>
        <div class="t-c">
          <div>订单商品</div>
          <div class="mt-20">{{name}}</div>
        </div>
        <!-- <div class="t-c">
          <div>订单数量</div>
          <div class="mt-20">x{{num}}</div>
        </div> -->
        <div class="t-c">
          <div>优惠金额</div>
          <div class="mt-20">{{coupon_price||'0.00'}}</div>
        </div>
        <div class="t-c">
          <div>金额</div>
          <div class="mt-20">{{price||'0.00'}}元</div>
        </div>
      </div>
      <div class="order-bottom">
        <!-- <span class="f12 mr-30 red">满5年年费优惠20%</span>
        <span class="f14">20000.00元</span>-->
      </div>
    </el-card>
    <el-card class="mt-20">
      <div class="f14">
         <!-- memberCoupon -->
        <span class="bd ">优惠券</span>
        <span class="xzyh cur mr-10" @click="xzy">选择优惠券</span>
        <span class="ml-30" style="color:#FF8547">{{couname}}</span>
      </div>
    </el-card>
    <el-card class="mt-20" v-if="type==1">
      <div>
        <span class="bd">是否开黄票</span>
        <el-radio class="ml-30" @change="ticket" v-model="is_yellow_ticket" label="1">开黄票</el-radio>
        <el-radio @change="ticket" v-model="is_yellow_ticket" label="0">不开黄票</el-radio>
      </div>
    </el-card>
    <!-- style="height:463px" -->
    <el-card class="mt-20">
      <div class="f14">
        <span class="bd" >是否开发票</span>
        <el-radio class="ml-30" v-model="radio" label="1" @change="change">开发票</el-radio>
        <el-radio v-model="radio" label="2" @change="change">不开发票</el-radio>
      </div>

      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="104px"
        hide-required-asterisk
        class="mt-30"
      >
        <el-row :gutter="0">
          <el-col :span="12" class="flex-j-c">
            <div>
              <el-form-item label="发票张数" prop="num">
                <el-select :disabled="able" class="w-320" v-model="form.num" placeholder="选择">
                  <el-option label="1张" value="1"></el-option>
                  <el-option label="2张" value="2"></el-option>
                  <el-option label="3张" value="3"></el-option>
                  <el-option label="4张" value="4"></el-option>
                  <el-option label="5张" value="5"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票类型" prop="open_type">
                <el-select :disabled="able" class="w-320" v-model="form.open_type" placeholder="选择">
                  <el-option label="增值普通发票" value="1"></el-option>
                  <el-option label="增值专用发票" value="2"></el-option>
                  <!-- <el-option label="普通电子发票" value="3"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="开票类型" prop="invoice_type">
                <el-select
                  :disabled="able"
                  class="w-320"
                  v-model="form.invoice_type"
                  placeholder="选择"
                >
                  <el-option label="单位" value="1"></el-option>
                  <el-option label="个人" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票抬头" prop="rise">
                <el-input :disabled="able" class="w-320" v-model="form.rise"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12" class="flex-j-c">
            <div>
              <el-form-item label="税号" prop="duty_paragraph">
                <el-input :disabled="able" class="w-320" v-model="form.duty_paragraph"></el-input>
              </el-form-item>
              <el-form-item label="单位地址" prop="unit_address">
                <el-input :disabled="able" class="w-320" v-model="form.unit_address"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="phone">
                <el-input :disabled="able" class="w-320" v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="开户银行" prop="bank_deposit">
                <el-input :disabled="able" class="w-320" v-model="form.bank_deposit"></el-input>
              </el-form-item>
              <el-form-item label="银行账户" prop="bank_account">
                <el-input :disabled="able" class="w-320" v-model="form.bank_account"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    
    <div class="allprice mt-20 flex-jb-al">
      <div>
        <div style="  color:#FF8547">优惠金额：-￥{{coupon_price||'0.00'}}</div>
        <div class="mt-10 f24">合计：{{Number(sumPrice).toFixed(2)}}元</div>
      </div>
      <div class="flex-al">
        <div class="f18 mr-20 cur goback" @click="$router.go(-1)">返回</div>
        <div class="gopay f18" @click="gofee">去缴费</div>
      </div>
    </div>
    <!-- 领取优惠券 -->
    <div class="pa ldiscount" v-if="abb">
      <div class="ld pa">
        <div class="pa flex-j-c" style="top:113px;left:38px; width:298px">
          <div class="ld-c t-c" v-for="item in lddata" :key="item.id">
            <div class="title">{{item.title}}</div>
            <div class="used">{{item.used==0?'平台优惠券':item.used==1?'新人优惠券':item.used==2?"商品优惠券":""}}</div>
            <div class="type">{{item.type==1?'满减券':item.type==2?'代金券':item.type==3?'折扣券':''}}</div>
          </div>
        </div>
        <div class="ma t-c pa" @click="getCoupon">马上领取</div>
      </div>
    </div>
    <!-- 黄票信息 -->
    <el-dialog
      :before-close="handleClose"
      center
      title="提交黄票信息"
      :visible.sync="dialogVisible"
      width="400px"
     >
      <div>
        <el-form
          ref="form1"
          :model="form1"
          :rules="rules1"
          :label-width="form1.client_type==2||form1.client_type==5?'80px':'110px'"
          hide-required-asterisk
        >
          <el-form-item
            prop="client_card"
            v-if="form1.client_type==1||form1.client_type==3||form1.client_type==4"
            label="社会信用代码"
          >
            <el-input v-model="form1.client_card"></el-input>
          </el-form-item>
          <el-form-item
            prop="client_name"
            :label="form1.client_type==2||form1.client_type==5?'真实姓名':'单位名称'"
          >
            <el-input v-model="form1.client_name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="realInfoEdit">确 定</el-button>
      </span>
    </el-dialog>
      <!-- 选择优惠券 -->
     <el-dialog
      :before-close="handleClose1"
      title="选择优惠券"
      :visible.sync="dialogVisible1"
      width="400px"
      >
      <div class="flex-j-c" style="flex-wrap: wrap">
        <div v-if="couponData.length==0">暂无优惠券可用</div>
        <div class="flex coup cur mr-10 mt-10" @click="clickCo(item)" v-for="item in couponData" :key="item.id">
          <div class="ld-c t-c">
            <div class="title">{{item.title}}</div>
            <div class="used">{{item.used==0?'平台优惠券':item.used==1?'新人优惠券':item.used==2?"商品优惠券":""}}</div>
            <div class="type">{{item.type==1?'满减券':item.type==2?'代金券':item.type==3?'折扣券':''}}</div>
          </div>
          <i v-if="coupon_id==item.id" class="el-icon-circle-check f18 blue mt-20"></i>
          <div
            v-else
            class="mt-20"
            style="width: 16px;height: 16px;border: 1px solid #bbb;border-radius: 50%;"
          ></div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="realInfoEdit">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
export default {
  created() {
    let type = this.$route.query.type;
    this.is_trusteeship=this.$route.query.is_trusteeship
    this.type = type || "";
    if (!type) {
      this.$router.replace("/index");
    }
    switch (type) {
      case "1":
        this.annualfee();
        break;
      case "4":
        this.monitoring();
        break;
      case "5":
        this.contract();
    }
    this.getCouponList(); //可领取优惠券
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (!myreg.test(value)) {
          callback(new Error("手机格式不正确"));
        } else {
          callback();
        }
      }
    };
    var client_name = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error(
            `请填写${
              this.form1.client_type == 2 || this.form1.client_type == 5
                ? "真实名称"
                : "单位名称"
            }`
          )
        );
      } else {
        callback();
      }
    };
    return {
      form1: {
        client_card: "",
        client_name: "",
        client_type: ""
      },
      is_trusteeship:'0',
      dialogVisible1:false,//选择优惠券
      dialogVisible: false,
      is_yellow_ticket: "0", //是否开黄票
      id: "", //合同id
      coupon_price: 0, //优惠金额
      tabindex: 1,
      lddata: [], //可领优惠券
      abb: false, //可领优惠券显示
      coupon_ids: "", //可领取优惠券的id
      coupon_id: "", //使用优惠券的id
      couponData: [], //商品可以使用的优惠券
      couname:'',//使用的优惠券
      able: true,
      radio: "2",
      order_no: "",
      type: "",
      name: "",
      price: "",
      num: 1,
      patent_id: "",
      years: "",
      is_plan: "",
      form: {
        // is_yellow_ticket: "",
        num: "",
        invoice_type: "",
        open_type: "",
        rise: "",
        duty_paragraph: "",
        unit_address: "",
        phone: "",
        bank_deposit: "",
        bank_account: ""
      },
      rules: {
        is_yellow_ticket: [
          { required: true, message: "请选择是否开具黄票", trigger: "change" }
        ],
        num: [{ required: true, message: "请选择发票张数", trigger: "change" }],
        open_type: [
          { required: true, message: "请选择发票类型", trigger: "change" }
        ],
        invoice_type: [
          { required: true, message: "请选择开票类型", trigger: "change" }
        ],
        rise: [{ required: true, message: "请填写发票抬头", trigger: "blur" }],
        duty_paragraph: [
          { required: true, message: "请填写税号", trigger: "blur" }
        ],
        unit_address: [
          { required: true, message: "请填写单位地址", trigger: "blur" }
        ],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        bank_deposit: [
          { required: true, message: "请填写开户银行", trigger: "blur" }
        ],
        bank_account: [
          { required: true, message: "请填写银行账户", trigger: "blur" }
        ]
      },
      rules1: {
        client_card: [
          { required: true, message: "请填写社会信用代码", trigger: "blur" }
        ],
        client_name: [{ validator: client_name, trigger: "blur" }]
      }
    };
  },
  methods: {
    //监控费预支付
    monitoring() {
      this.time_id = this.$route.query.time_id;
      this.row_id = this.$route.query.row_id;
      this.$request
        .post("/client/monitoring/pay", {
          time_id: this.time_id,
          row_id: this.row_id,
          is_plan: "1",
          coupon_id: this.coupon_id
        })
        .then(res => {
          if(res.data.code==200){
               this.name = res.data.data.name;
          this.type = res.data.data.type;
          this.price = res.data.data.price;
          this.coupon_price = res.data.data.coupon_price;
          this.dialogVisible1=false
          if(this.coupon_id){
            this.couponData.forEach(item=>{
              if(item.id==this.coupon_id){
                this.couname='已选择：'+item.title+',  优惠金额：-￥'+res.data.data.coupon_price
              }
            })
          }else{
            this.couname=''
          }
          }else{
            this.coupon_id=''
             this.$message(res.data.msg);
             this.couname=''
             this.dialogVisible1=false
          }
        
        });
    },
    //年费预支付
    annualfee() {
      this.patent_id = this.$route.query.patent_id;
      this.years = this.$route.query.years;
      this.$request
        .post("/client/annual_fee/pay", {
          patent_id: this.patent_id,
          years: this.years,
          is_plan: "1",
          coupon_id: this.coupon_id,
           is_trusteeship:this.is_trusteeship
        })
        .then(res => {
         if(res.data.code==200){
            this.name = res.data.data.name;
          this.type = res.data.data.type;
          this.price = res.data.data.price;
          this.coupon_price = res.data.data.coupon_price;
          this.dialogVisible1=false
          if(this.coupon_id){
            this.couponData.forEach(item=>{
              if(item.id==this.coupon_id){
               this.couname='已选择：'+item.title+',  优惠金额：-￥'+res.data.data.coupon_price
              }
            })
          }else{
            this.couname=''
          }
         }else{
            this.coupon_id=''
             this.$message(res.data.msg);
             this.couname=''
             this.dialogVisible1=false
          }
         
        });
    },
    //合同缴费预支付
    contract() {
      this.id = this.$route.query.id;
      this.$request
        .post("/client/Contract/pay", {
          id: this.id,
          is_plan: "1",
          coupon_id: this.coupon_id
        })
        .then(res => {
          if(res.data.code==200){
            this.name = res.data.data.name;
          this.type = res.data.data.type;
          this.price = res.data.data.price;
          this.coupon_price = res.data.data.coupon_price;
            this.dialogVisible1=false
          if(this.coupon_id){
            this.couponData.forEach(item=>{
              if(item.id==this.coupon_id){
                this.couname='已选择：'+item.title+', 优惠金额：-￥'+res.data.data.coupon_price
              }
            })
          }else{
            this.couname=''
          }
          }else{
            this.coupon_id=''
             this.$message(res.data.msg);
             this.couname=''
             this.dialogVisible1=false
          }
         
        });
    },
    // 去缴费
    gofee() {
      // is_invoice	1开发票   0不开发票
      if (this.radio == 1) {
        this.$refs.form.validate(res => {
          if (res) {
            switch (this.type) {
              case 1:
                this.$request
                  .post("/client/annual_fee/pay", {
                    patent_id: this.patent_id,
                    years: this.years,
                    is_plan: "0",
                    coupon_id: this.coupon_id,
                    is_yellow_ticket: this.is_yellow_ticket,
                    is_invoice:1,
                    is_trusteeship:this.is_trusteeship,
                    ...this.form
                  
                  })
                  .then(res => {
                    if (res.data.code == 200) {
                      this.$router.replace({
                        path: "/recharge",
                        query: {
                          type: "no_num",
                          order_no: res.data.data.order_no,
                          price: this.sumPrice
                        }
                      });
                    }
                  });
                break;
              case 4:
                this.$request
                  .post("/client/monitoring/pay", {
                    time_id: this.time_id,
                    row_id: this.row_id,
                    coupon_id: this.coupon_id,
                    is_plan: "0",
                    is_invoice:1,
                    ...this.form
                  })
                  .then(res => {
                    if (res.data.code == 200) {
                      this.$router.replace({
                        path: "/recharge",
                        query: {
                          type: "no_num",
                          order_no: res.data.data.order_no,
                          price: this.sumPrice
                        }
                      });
                    }
                  });

                break;
              case 5:
                this.$request
                  .post("/client/Contract/pay", {
                    id: this.id,
                    coupon_id: this.coupon_id,
                    is_plan: "0",
                    is_invoice:1,
                    ...this.form
                  })
                  .then(res => {
                    if (res.data.code == 200) {
                      this.$router.replace({
                        path: "/recharge",
                        query: {
                          type: "no_num",
                          order_no: res.data.data.order_no,
                          price: this.sumPrice
                        }
                      });
                    }
                  });
            }
          }
        });
      } else {
        switch (this.type) {
          case 1:
            this.$request
              .post("/client/annual_fee/pay", {
                patent_id: this.patent_id,
                years: this.years,
                coupon_id: this.coupon_id,
                is_plan: "0",
                is_yellow_ticket: this.is_yellow_ticket,
                is_trusteeship:this.is_trusteeship
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$router.replace({
                    path: "/recharge",
                    query: {
                      type: "no_num",
                      order_no: res.data.data.order_no,
                      price: this.sumPrice
                    }
                  });
                }
              });
            break;
          case 4:
            this.$request
              .post("/client/monitoring/pay", {
                time_id: this.time_id,
                row_id: this.row_id,
                coupon_id: this.coupon_id,
                is_plan: "0"
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$router.replace({
                    path: "/recharge",
                    query: {
                      type: "no_num",
                      order_no: res.data.data.order_no,
                      price: this.sumPrice
                    }
                  });
                }
              });
            break;
          case 5:
            this.$request
              .post("/client/Contract/pay", {
                id: this.id,
                coupon_id: this.coupon_id,
                is_plan: "0"
              })
              .then(res => {
                if (res.data.code == 200) {
                  this.$router.replace({
                    path: "/recharge",
                    query: {
                      type: "no_num",
                      order_no: res.data.data.order_no,
                      price: this.sumPrice
                    }
                  });
                }
              });
        }
      }
    },
    change(index) {
      index == 1 ? (this.able = false) : (this.able = true);
    },
    // 可领取优惠券
    getCouponList() {
      this.$request
        .get(`/client/coupon/getCouponList?astrict_id=${this.type}`)
        .then(res => {
          if (res.data.code == 200) {
            this.lddata = res.data.data.slice(0, 3);
            if (this.lddata.length > 0) {
              this.abb = true;
            }
            let arr = [];
            res.data.data.forEach(item => {
              arr.push(item.id);
            });
            this.coupon_ids = arr;
          }
        });
    },
    // 领取优惠券
    getCoupon() {
      this.$request
        .post("/client/coupon/getCoupon", { coupon_id: this.coupon_ids })
        .then(res => {
          if (res.data.code == 200) {
          }
          this.abb = false;
        });
    },
    //用户已领取优惠券
    memberCoupon() {
      this.$request
        .get(
          `/client/coupon/memberCoupon?page=1&limit=20&astrict_id=${this.type}`
        )
        .then(res => {
          if (res.data.code == 200) {
             this.couponData=[]
            res.data.data.forEach(item => {
              if (item.status == 1) {
                this.couponData.push(item);
              }
            });
            if(this.couponData.length==0){
              this.$message('暂无可使用优惠券')
              return
            }

            this.dialogVisible1=true
          }
          if(res.data.code == 400){
             this.$message('暂无可使用优惠券')
          }
        });
    },
    //选择使用优惠券
    clickCo(item) {
      if (this.coupon_id == item.id) {
        this.coupon_id = "";
      } else {
        this.coupon_id = item.id;
      }
      switch (this.type) {
        case 1:
          this.annualfee();
          break;
        case 4:
          this.monitoring();
          break;
        case 5:
          this.contract();
      }
    },
    // 显示可选择的优惠券
    xzy(){
      this.memberCoupon()
    },
    handleClose1(){
      this.dialogVisible1=false
    },
    // 开黄票
    ticket(index) {
      if (index == 1) {
        this.$request.get("/client/member/cientInfo").then(res => {
          if (res.data.code == 200) {
            let form1 = {};
            form1.client_card =
              res.data.data.client_card == 0 ? "" : res.data.data.client_card;
            form1.client_name =
              res.data.data.client_name == 0 ? "" : res.data.data.client_name;
            form1.client_type = res.data.data.client_type;
            this.form1 = form1;
            this.dialogVisible = true;
          } else {
            this.is_yellow_ticket = 0;
          }
        });
      }
    },
    handleClose() {
      this.is_yellow_ticket = "0";
      this.dialogVisible = false;
    },
    realInfoEdit() {
      this.$refs.form1.validate(res => {
        if (res) {
          this.$request
            .post("/client/member/realInfoEdit", { ...this.form1 })
            .then(res => {
              if (res.data.code == 200) {
                this.dialogVisible = false;
              }else{
                this.is_yellow_ticket='0'
              }
            });
        }
      });
    }
  },
  computed: {
    sumPrice() {
      return this.price - this.coupon_price <= 0
        ? "0.00"
        : this.price - this.coupon_price;
    }
  }
};
</script>

<style scoped>
.additem-title {
  border-bottom: 1px solid #e6e6e6;
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  padding: 10px;
}
.additem-title span.active {
  border-bottom: 2px solid #2962ff;
}
.order-list {
  justify-content: space-around;
  padding: 34px 0;
}
.order-bottom {
  text-align: right;
  border-top: 1px solid #e6e6e6;
  padding-top: 30px;
}
.w-320 {
  width: 320px;
}
.el-card >>> .el-input__inner {
  height: 32px;
}
.el-form-item__label {
  color: #000;
}
.allprice {
  /* position: fixed; */
  background: #3d4457;
  border-radius: 8px;
  padding: 20px 50px;
  color: #fff;
  /* bottom: 20px;
  right: 20px;
  left: 198px;
  min-width: 1249px; */
}
.gopay {
  width: 130px;
  height: 48px;
  opacity: 1;
  background: #ff8547;
  border-radius: 26px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.goback {
  width: 130px;
  height: 48px;
  opacity: 1;
  background: #bbb;
  border-radius: 26px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}
.el-card >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #2962ff;
  background: #2962ff;
}
.el-card >>> .el-radio__input.is-checked + .el-radio__label {
  color: #2962ff;
}
.ldiscount {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.ld {
  width: 380px;
  height: 320px;
  top: 50%;
  left: 50%;
  margin-left: -180px;
  margin-top: -160px;
  background-image: url("../assets/images/yh2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ld-c {
  width: 90px;
  height: 98px;
  background-image: url("../assets/images/yh1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px solid transparent;
  margin-left: 6px;
}
.ld-c .title {
  font-size: 10px;
  font-weight: 600;
  color: #9a4a30;
  margin-top: 10px;
 border-bottom: 0;
  padding: 0px; 

}
.ld-c .type {
  font-size: 12px;
  font-weight: 600;
  color: #bc7e35;
  margin-top: 18px;
}
.ld-c .used {
  margin-top: 10px;
  font-size: 10px;
  color: #9a4a30;
}
.ma {
  font-size: 14px;
  color: #fff;
  width: 120px;
  height: 32px;
  line-height: 32px;
  opacity: 1;
  background: linear-gradient(180deg, #ffa5a5, #ff5454 100%);
  border-radius: 33px;
  bottom: 30px;
  left: 120px;
  cursor: pointer;
}
.coup {
  width: 100px;
  height: 156px;
  border: 1px solid #e4e4e4;
  border-radius: 9px;
  flex-direction: column;
  align-items: center;
}
.coup .ld-c {
  margin-left: 0px;
  margin-top: 4px;
}
.xzyh{
  display: inline-block;
  color:#fff;
  background-color:#2962ff;
  padding:3px 6px;
  border-radius: 6px;
  font-size: 12px;
  margin-left:60px;
}
</style>
